@import 'src/utils/utils';

.modal {

  .request {
    flex-grow: 1;
  }

  &__container {
    position: relative;
    width: 100%;
    box-sizing: border-box;

    @media(min-width: $md) {
      padding-top: 30px;
      padding-right: 30px;
      padding-bottom: 30px;
      padding-left: 30px;
    }
  }

  &__wrapper {
    position: relative;
    display: flex;
    max-width: 1000px;
    min-height: calc(var(--vh, 1vh) * 100);
    margin: auto;
    padding-top: 14px;
    padding-right: 16px;
    padding-bottom: 40px;
    padding-left: 16px;
    background-color: $Main-2;

    @media(min-width: $md) {
      min-height: unset;
      padding: 30px;
      border-radius: 20px;
    }
  }

  &__close {
    position: fixed;
    top: 20px;
    right: 16px;
    z-index: 5;
    display: flex;
    width: 28px;
    height: 28px;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    border: 0;
    border-radius: 50%;
    color: $Main-1;
    background-color: $Main-4;
    transition:
      transform 0.5s,
      color 0.3s;
    outline: none;
    cursor: pointer;

    @media(min-width: $md) {
      position: absolute;
      top: 15px;
      right: 15px;
      width: 38px;
      height: 38px;
    }

    &:hover {
      @media(min-width: $lg) {
        color: $Corporate-2;
        transform: rotate(90deg);
      }
    }

    span {
      position: relative;
      display: inline-block;
      width: 14px;
      height: 2px;
      background: transparent;
      vertical-align: bottom;

      @media(min-width: $md) {
        width: 16px;
      }

      &:before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: currentcolor;
        content: '';
        transform: rotate(45deg);

        @media(min-width: $md) {
          height: 2px;
        }
      }

      &:after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: currentcolor;
        content: '';
        transform: rotate(-45deg);

        @media(min-width: $md) {
          height: 2px;
        }
      }
    }
  }

  &--delete-user {

    .request__title {
      @include Text-18-bold;
    }
  }

  &--small {

    .modal__close {
      position: absolute;
    }

    .modal__wrapper {
      max-width: 560px;
      min-height: unset;
      padding: 20px;
      border-radius: 10px;
    }

    .modal__container {
      padding: 16px;
    }
  }

  &--consultation {

    .request__fields {
      @media(min-width: $md) {
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
      }
    }
  }

  &--add-user {

    .request__fields {
      @media(min-width: $md) {
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
      }
    }

    .request__legal {
      grid-template-columns: 1fr;
    }
  }
}